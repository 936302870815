body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Goldplay';
  letter-spacing: 0.025em;

}

html {
  scroll-behavior: smooth;
}

h3, h2{
  margin-top:0px;
}

@font-face {
  font-family: "Goldplay";
  src: url(./assets/fonts/Goldplay\ Medium.otf);
}
@font-face {
  font-family: "Goldplay";
  src: url(./assets/fonts/Goldplay\ Bold.otf);
  font-weight: bold;
}

.bold{
  font-weight: bold;
}

p{
  margin:0px;
}

.title{
  font-weight: bold;
  font-size: 3rem;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.05em;
}

.purple{

  color:#251463;
}

/* *{
  border: 1px solid red;
} */

.flexSection{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1000px;


}

@media (max-width: 1080px) {

  .flexSection{
    flex-direction: column;
    padding: 0px 15px;
  }
       
}